/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import TagManager from 'react-gtm-module/dist/TagManager';
import '../styles/globals.css';

function MyApp({ Component, pageProps }) {
  // Google Tag Manager
  // @see https://github.com/vercel/next.js/discussions/15518#discussioncomment-135390
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({ gtmId: 'GTM-MQXL8NW' });
    }
  }, []);

  return <Component {...pageProps} />;
}

export default MyApp;
